<template>
    <v-card rounded="lg" class="rounded-lg pa-6 pt-2">
        <div class="headingIndicators colTemp">
            <h2 class="black--text mb-3">{{$t('selectedTemplate')}}</h2>
            <span class="btnResetTemp" v-if="!switchEnvelope && (template.is_different_from_model || !template.model_source_id)">
                <v-btn small depressed class="bg-gradient mb-4" @click="callForm('confirm', formConvertUrl + template.id)">
                    {{ $t('convertToModelTemplate') }}
                </v-btn>
                <v-btn v-if="template.is_different_from_model" small depressed class="bg-gradient mb-4 ml-3" @click="openConfirmModal(template.id)">
                    {{ $t('resetTemplate') }}
                </v-btn>
            </span>
            <span class="btnResetTemp" v-if="switchEnvelope && templateEnveloppe.id && (templateEnveloppe.is_different_from_model || !templateEnveloppe.model_source_id)">
                <v-btn small depressed class="bg-gradient mb-4" @click="callForm('confirm', formConvertUrl + templateEnveloppe.id)">
                    {{ $t('convertToModelTemplate') }}
                </v-btn>
                <v-btn v-if="templateEnveloppe.is_different_from_model" small depressed class="bg-gradient mb-4 ml-3" @click="openConfirmModal(templateEnveloppe.id)">
                    {{ $t('resetTemplate') }}
                </v-btn>
            </span>
        </div>
        <p :class="paramsShown && (typeof paramsShown.subject !== 'undefined') ? 'mb-0' : ''" v-if="paramsShown && ((typeof paramsShown.senderName !== 'undefined' && paramsShown.senderName !== '') || (typeof paramsShown.sender !== 'undefined' && paramsShown.sender !== ''))">
            <b>{{ $t('from') }} : </b> <span v-if="(typeof paramsShown.senderName !== 'undefined')">{{paramsShown.senderName}}</span> <span v-if="(typeof paramsShown.sender !== 'undefined')">&#60;{{paramsShown.sender}}&#62;</span>
        </p>
        <p v-if="paramsShown && (typeof paramsShown.subject !== 'undefined')">
            <b>{{ $t('subject') }} : </b> {{paramsShown.subject}}
        </p>
        <p v-if="templateShown && (typeof templateShown.link !== 'undefined')">
            <b>{{ $t('link') }} : </b> {{templateShown.link}}
        </p>

        <!-- EMAIL -->
        <v-alert color="warning" border="left" text dense v-if="paramsShown && ['EMAIL'].includes(template.canal) && ((!paramsShown.hasOwnProperty('subject') || !paramsShown.subject) || (!paramsShown.hasOwnProperty('sender') || !paramsShown.sender) || (!paramsShown.hasOwnProperty('senderName') || !paramsShown.senderName))">
            <v-row align="center">
                <v-col class="grow">
                    {{ $t("paramsAreMissing") }} : 
                    <li v-if="!paramsShown.hasOwnProperty('subject') || !paramsShown.subject">{{ $t("subject") }}</li>
                    <li v-if="!paramsShown.hasOwnProperty('sender') || !paramsShown.sender">{{ $t("sender") }}</li>
                    <li v-if="!paramsShown.hasOwnProperty('senderName') || !paramsShown.senderName">{{ $t("senderName") }}</li>
                </v-col>
            </v-row>
        </v-alert>
        <!-- VMS -->
        <v-alert color="warning" border="left" text dense v-if="paramsShown && ['VMS'].includes(template.canal) && (!paramsShown.hasOwnProperty('type') || !paramsShown.type)">
            <v-row align="center">
                <v-col class="grow">
                    {{ $t("paramsAreMissing") }} : 
                    <li>{{ $t("type") }}</li>
                </v-col>
            </v-row>
        </v-alert>
        <!-- MAILING -->
        <v-alert color="warning" border="left" text dense v-if="campaign && campaign.type === 'CAMP_OUT_P' && campaign.sousType === 'campMAILINGout' && (!campaign.config.template_envelope)">
            <v-row align="center">
                <v-col class="grow">
                    {{ $t("templateIsMissing") }}
                </v-col>
            </v-row>
        </v-alert>

        <div >
            <div v-if="campaign && campaign.config && campaign.config.canal && campaign.config.canal == 'MAILING'">
                <v-switch v-model="switchEnvelope" @change="changeSwitch" :label="$t('showEnveloppeTemplate')"></v-switch>
                <vCustomLibraryPicker  @selectModel="selectModel" :returnEmit="true" :key="keyPicker" v-if="!switchEnvelope" :model="{'operation_id': $route.params.operation_id, 'template' :{'id': template.id, 'name':template.name, 'image':null, 'template': templateShown}}" :fieldName="'template'" :field="{libType: campaign.config.canal, inCampaign: true, campaign: campaign}" @refreshAllTemplates="refreshAllTemplates"/>
                <vCustomLibraryPicker  @selectModel="selectModelEnveloppe" :returnEmit="true" :key="'env_'+keyPicker" v-else :model="{'operation_id': $route.params.operation_id, 'template' :{'id': templateEnveloppe.id, 'name':templateEnveloppe.name, 'image':null, 'template': templateEnvShown}}" :fieldName="'template'" :field="{libType: 'ENVELOPE', inCampaign: true, campaign: campaign}" @refreshAllTemplates="refreshAllTemplates"/>
            </div>
            <div v-else-if="campaign && campaign.type && campaign.type =='CAMP_IN'">
                <vCustomLibraryPicker @selectModel="selectModel" :returnEmit="true" :key="keyPicker" :model="{'operation_id': $route.params.operation_id, 'template' :{'id': template.id, 'name':template.name, 'image':null, 'template': templateShown}}" :fieldName="'template'" :field="{libType: 'TEL', inCampaign: true, campaign: campaign}" @refreshAllTemplates="refreshAllTemplates"/>
            </div>
            <div v-else>
                <vCustomLibraryPicker @selectModel="selectModel" :returnEmit="true" :key="keyPicker" :model="{'operation_id': $route.params.operation_id, 'template' :{'id': template.id, 'name':template.name, 'image':null, 'template': templateShown}}" :fieldName="'template'" :field="{libType: campaign.config.canal, inCampaign: true, campaign: campaign}" @refreshAllTemplates="refreshAllTemplates"/>
            </div>
        </div>

        <ConfirmDialogComponent ref="confirmDialog" />
        <FormDialogComponent v-if="form" ref="componentForm" :type="formType" :form="form" :action="'SET_DATA'" />
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import vCustomLibraryPicker from '../../../components/forms/inputs/v-custom-library-picker.vue'
import ConfirmDialogComponent from "@/components/dialogs/ConfirmDialogComponent";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins'

export default {
    name: "CampaignTemplate",
    components: {vCustomLibraryPicker, ConfirmDialogComponent, FormDialogComponent},
    props: ['campaign'],
    mixins:[getForm],
    data() {
        return {
            template:{},
            templateEnveloppe:{},
            loader:false,
            switchEnvelope: false,
            keyPicker:0,
            formConvertUrl: "/library/getFormBeforeConvert?id=",
            formType: "",
            formUrl: "",
            form: null,
        }
    },
    created() {
        setTimeout(() => {
            this.refreshAllTemplates(false);
        }, 700);
        //this.refreshAllTemplates(false);
    },
    watch: {
        campaign(newVal){
            this.refreshAllTemplates(false);
        },
    },
    computed: {
        templateShown: function() {
            if(this.template && this.template.template) {
                if(this.template.template_compiled)
                    return this.template.template_compiled;
                else
                    return this.template.template;
            }
            
            return false;
        },
        templateEnvShown: function(){
            if(this.templateEnveloppe && this.templateEnveloppe.template) {
                if(this.showCompiledTemplate && this.templateEnveloppe.template_compiled)
                    return this.templateEnveloppe.template_compiled;
                else
                    return this.templateEnveloppe.template;
            }
            
            return false;
        },
        paramsShown: function() {
            if(this.template && this.template.params) {
                if(this.template.params_compiled)
                    return this.template.params_compiled;
                else
                    return this.template.params;
            }
            
            return false;
        }
    },
    methods: {
        selectModel(model){
            let payload = {
                "id": this.campaign.id,
                "config": this.campaign.config,
                "config.template" : model
            }
            if(this.campaign.operation_id)
                payload.operation_id = this.campaign.operation_id.id ? this.campaign.operation_id.id : this.campaign.operation_id
            GenericDataService.postData('/retroplanning/set?id='+this.campaign.id, payload).then((response) => {
                this.$store.commit("campaign/setCampaignData", response.data.data);
                this.refreshAllTemplates();
            })
        },
        selectModelEnveloppe(model){
            let payload = {
                "id": this.campaign.id,
                "config": this.campaign.config,
                "config.template_envelope" : model
            }
            if(this.campaign.operation_id)
                payload.operation_id = this.campaign.operation_id.id ? this.campaign.operation_id.id : this.campaign.operation_id
            GenericDataService.postData('/retroplanning/set?id='+this.campaign.id, payload).then((response) => {
                this.$store.commit("campaign/setCampaignData", response.data.data);
                this.refreshAllTemplates();
            })
        },
        changeSwitch($evt){
            if($evt)
                this.refreshTemplateEnveloppe()
            else
                this.refreshTemplate()

            this.keyPicker++
            this.$forceUpdate()
        },
        resizeIframe(id) {
            var iframe = document.getElementById(id);
            iframe.style.width = "100%";
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        },
        refreshAllTemplates(loadActions = true) {
            if(loadActions) {
                GenericDataService.postData("/retroplanning/get?id=" + this.$route.params.id).then((response) => {
                    this.$store.commit("campaign/setCampaignData", response.data.data);
                    setTimeout(() => {
                        this.refreshTemplate();
                        this.refreshTemplateEnveloppe();
                    }, 1000);
                });
            } else {
                this.refreshTemplate();
                this.refreshTemplateEnveloppe();
            }
        },
        refreshTemplate() {
            let id = null;
            this.loader = true;
            if(this.campaign.config && this.campaign.config.template) {
                id = this.campaign.config.template.id ? this.campaign.config.template.id : this.campaign.config.template;
                GenericDataService.getDataThrowError('/retroplanning/' + this.campaign.id + '/getCompiledTemplate?id=' + id).then((response) => {
                    if(response.data.data){
                        this.template = response.data.data;
                        this.loader = false;
                    }
                });
            } else {
                this.template = {};
                this.loader = false;
            }
        },
        refreshTemplateEnveloppe(){
            let id = null;
            this.loader = true;
            if(this.campaign.config && this.campaign.config.template_envelope) {
                id = this.campaign.config.template_envelope.id ? this.campaign.config.template_envelope.id : this.campaign.config.template_envelope;
                GenericDataService.getDataThrowError('/retroplanning/' + this.campaign.id + '/getCompiledTemplate?id=' + id).then((response) => {
                    if(response.data.data){
                        this.templateEnveloppe = response.data.data;
                        this.loader = false;
                    }
                });
            } else {
                this.templateEnveloppe = {};
                this.loader = false;
            }
        },
        async openConfirmModal(templateId) {
            if (await this.$refs.confirmDialog.open(this.$t("confirmResetTemplate"), this.$t("confirmResetTemplateMessage"))) {
                GenericDataService.postData("/template/" + templateId + "/resetTemplate").then((response) => {
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                    this.refreshTemplate();
                    this.refreshTemplateEnveloppe();
                });
            }
        },
        callForm(formType, formUrl) {
            this.formType = formType;
            this.formUrl = formUrl;
            this.getForm(formUrl);
        },
        customRefresh() {
            this.refreshTemplate();
            this.refreshTemplateEnveloppe();
        }
    },
}
</script>

<style lang="scss">
    .colTemp {
        position:relative;
    }
    .btnResetTemp {
        position: absolute;
        z-index: 5;
        top: 10px;
        right: 0px;
    }
</style>